import PropTypes from 'prop-types';
import React, { useState } from 'react';

import ConfirmDeleteModal from '../../components/Modal/ConfirmDeleteModal';
import ProductIcon from '../ProductElements/ProductIcon';
import Switch from '../Switch/Switch';

import { ReactComponent as Drag } from '../../assets/icons/drag.svg';
import { ReactComponent as Edit } from '../../assets/icons/edit.svg';
import { ReactComponent as Trash } from '../../assets/icons/trash.svg';

import './RecommendedItemManage.scss';

const RecommendedItemManage = ({ id, hasCheckbox, handleItemClick, checked, onChange, removeItem, item, editItem, showVisible, showEdit, handleVisibleChange }) => {
    const type = item.type;

    const getTypeColor = (type) => {
        switch (type) {
            case 'Dashboard':
                return 'orange';
            case 'Table':
                return 'blue';
            case 'Data Set' :
                return 'blue';
            case 'Data Entity' :
                return 'blue';
            case 'Schema':
                return 'blue';
            case 'Data Science Product':
                return 'purple';
            case 'Search Term':
                return 'green';
            case 'Feature':
                return 'green';
            case 'Gen AI Use Case':
                return 'green';
            default:
                return '';
        }
    }

    const [showModalConfirmation, setShowModalConfirmation] = useState(false);

    function handleCheckboxChange(event) {
        if (event.target.checked) {
            const recommendedItem = event.target.closest(`.recommended-item-manage.${getTypeColor(type)}`);
            recommendedItem.className = `recommended-item-manage ${getTypeColor(type)} checked`;
        } else {
            const recommendedItem = event.target.closest(`.recommended-item-manage.${getTypeColor(type)}.checked`);
            recommendedItem.className = `recommended-item-manage ${getTypeColor(type)}`;
        }
        onChange();
    }

    function convertUTCDateToLocalDate(date) {
        var newDate = new Date(date.getTime()-date.getTimezoneOffset()*60*1000);
        
        return newDate.toLocaleDateString('en-GB', {
            month: 'short', day: 'numeric', year: 'numeric'
        });   
    }

    return (
        <>
            
            <div className={`recommended-item-manage ${getTypeColor(type)}${showVisible && !item.isVisible ? ' disabled' : ''}`}  id={id} data-testid={id} onClick={handleItemClick && handleItemClick}>
                <div className='recommended-content-manage'>
                    {hasCheckbox ? <input type="checkbox" id="selected-checkbox" data-testid="selected-checkbox" onChange={handleCheckboxChange} checked={checked}/> : null}
                    <ProductIcon productType={type} />
                    <div className='recommended-title' dangerouslySetInnerHTML={{ __html: `<p>${item.content}</p>` }} >
                    </div>
                </div>
                {item.isPublished ? 
                    <p className={`recommended-date-manage${!item.isVisible ? ' disabled' : ''}`}>{convertUTCDateToLocalDate(new Date(item.modifiedOn)).toString()}</p>
                    :
                    <p className='recommended-date-manage'>Draft</p>
                }
                <div className = 'icons'>
                    {showEdit && <Edit className='edit' onClick={editItem}></Edit>}
                    {showVisible ? 
                        <>
                            <p>Visible</p>
                            <Switch isSwitched={item.isVisible} handleChange={() => handleVisibleChange(item.id, !item.isVisible)} />
                        </>
                        : <Trash className='trash' onClick={() => setShowModalConfirmation(true)}></Trash>
                    }
                    {hasCheckbox ? 
                     null: <Drag className = 'drag'></Drag>}
                </div>
            </div>

            <ConfirmDeleteModal
                id={`${id}-confirm-modal`}
                isOpen={showModalConfirmation}
                handleClose={() => setShowModalConfirmation(false)}
                handleDelete = {() => {removeItem(item); setShowModalConfirmation(false);}}
                header="Confirmation"
                message='Are you sure you want to remove this update? Removing an update will immediately remove it from your drafts. This action cannot be undone. '
            >
            </ConfirmDeleteModal>
        </>
    )
}

RecommendedItemManage.propTypes = {
    id: PropTypes.string,
    hasCheckbox: PropTypes.bool,
    handleItemClick: PropTypes.func,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    removeItem: PropTypes.func,
    item: PropTypes.object,
    editItem: PropTypes.func,
    showVisible: PropTypes.bool,
    showEdit: PropTypes.bool,
    handleVisibleChange: PropTypes.func
}

export default RecommendedItemManage;